.card {
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-color: var(--card-border-color);
    position: relative;
    border: var(--card-border);

    .card-overlay {
        background-color: var(--card-overlay-bg-color);

        &:hover {
            background-color: var(--card-overlay-hover-bg-color);
        }
    }
}